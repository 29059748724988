// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import send_sms from './send_sms'
import contact from './contact'
import sender_id from './sender_id'
import campaign from './campaign'
import Notifications from './Notifications'
import jobs from './jobs'
import receiving_sms from './receiving_sms'
import blacklist from './blacklist'
import settings from './settings'
import billing from './billing'
import packagesList from './sellPackages'
import  templatesData from './sms_template'
import fieldsData from './fields'
import announcements from './announcements'
import shortCutsData from './shortCuts'
import support from './support'
import reports from './reports'
import routes from './routes'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  send_sms,
  contact,
  sender_id,
  campaign,
  Notifications,
  jobs,
  receiving_sms,
  blacklist,
  settings,
  billing,
  packagesList,
  templatesData,
  announces: announcements,
  fieldsData,
  shortCutsData,
  support,
  reports,
  routes
})

export default rootReducer
