const initialState = {
  userRoutes: [],
};

const userRouteReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER_ROUTES":
      return {
        ...state,
        userRoutes: action.userRoutes,
      };

    default:
      return state;
  }
};

export default userRouteReducer;
